import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

import Layout from "../../components/layout"
import Header from "../../components/header"

import { useInView } from "react-intersection-observer"
import Projects from "../../components/projects"

const Typography = () => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  })

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const { mtypefaceCloudinary } = useStaticQuery(graphql`
    query {
      mtypefaceCloudinary: allCloudinaryMedia(
        filter: { public_id: { regex: "/rgrq/mtypeface/" } }
      ) {
        edges {
          node {
            secure_url
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Header />
      <div className="project">
        <div className="container-fluid subheader">
          <div className="row">
            <div className="col-lg-4">
              <h2>MOLA</h2>
            </div>
            <div className="offset-lg-1 col-lg-3">
              <h2>2020</h2>
            </div>
            <div className="col-lg-4">
              <h2>Typeface Design, Specimen</h2>
            </div>
          </div>
        </div>
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-12 hero hero-typefaces">
              <h3 className="typeface-mola">MOLA</h3>
              <div className={`hero-footer ${inView}`}>
                <div className="row justify-content-between align-items-end">
                  <div className="col-lg-4 d-none d-md-block">
                  </div>
                  <div className="offset-md-1 col-md-3 d-none d-md-block">
                    <h2>
                      <Link to="/">rg-rq.com</Link>
                    </h2>
                  </div>
                  <div className="offset-md-2 col-md-2">
                    <button onClick={scrollTop}>
                      <h2>
                        Back up
                        {inView ? (
                          <img
                            className="img-fluid"
                            src="/Up.svg"
                            alt="cheveron icon"
                          />
                        ) : (
                          <img
                            className="img-fluid"
                            src="/Up-white.svg"
                            alt="cheveron icon"
                          />
                        )}
                      </h2>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid content">
          <div className="row text-section">
            <div className="col-lg-5">
              <p>Inspiration</p>
            </div>
            <div className="col-lg-7">
              <p className="m-d-60">
                Mola is a serif text family with a renaissance (old-style) look
                and with a significantly elevated x-height. It was designed for
                text while retaining characteristics to its origins of the First
                Mexican Flag. Mola has a style inspired by the characteristics
                of the community handwriting spirit while maintaining an elegant
                posture. The typeface celebrates the community of Los Angeles,
                its murals, and México's history of the Virgen de Guadalupe.
              </p>
              <div className="row">
                <div className="col-12 col-md-6">
                  <img
                    className="img-fluid"
                    src={mtypefaceCloudinary.edges[0].node.secure_url}
                    alt=""
                  />
                </div>
                <div className="col-12 col-md-6">
                  <img
                    className="img-fluid"
                    src={mtypefaceCloudinary.edges[1].node.secure_url}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <img
                className="img-fluid"
                src={mtypefaceCloudinary.edges[2].node.secure_url}
                alt=""
              />
            </div>
          </div>

          <div className="row justify-content-center text-section">
            <div className="col-lg-12">
              <h4 className="mola-130 text-center">Tacos Ave 26</h4>
              <h4 className="mola-130 text-center">Libreria</h4>
              <h4 className="mola-130 text-center">Mexico</h4>
              <h4 className="mola-130 text-center">Carta Blanca</h4>
              <h4 className="mola-130 text-center">Gente</h4>
              <h4 className="mola-130 text-center">Chalino Sanchez</h4>
              <h4 className="mola-100 p-t-80">Cruzito: </h4>
              <h4 className="mola-100 p-l-60">
                Orale, mi hijo, this is
                <br />
                Quetzalcoatl, the great
                <br />
                Aztec poet king.
              </h4>
            </div>
            <div className="col-lg-10 p-t-80">
              <img
                className="img-fluid"
                src={mtypefaceCloudinary.edges[3].node.secure_url}
                alt=""
              />
              <p>By Jesús Helgura</p>
              <h4 className="mola-100 p-t-80">
                He ruled the kingdom of <br />
                Aztlan... that was from
                <br />
                Mexico all the way up
                <br />
                here to Califas.
              </h4>
            </div>
            <div className="col-12">
              <h4 className="mola-100 p-t-80">********************</h4>
              <h4 className="mola-100 p-t-80">
                Blood in Blood out (1993)
                <br />
                00 : 08 minutes : 03 seconds 
              </h4>
              <h4 className="mola-250 text-center p-t-400">MEXIHCAH</h4>
              <h4 className="mola-250 text-center p-t-200">MAYA</h4>
              <h4 className="mola-250 text-center p-t-200">AZTLAN</h4>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <img
                className="img-fluid"
                src={mtypefaceCloudinary.edges[4].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={mtypefaceCloudinary.edges[5].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={mtypefaceCloudinary.edges[6].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={mtypefaceCloudinary.edges[7].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={mtypefaceCloudinary.edges[8].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={mtypefaceCloudinary.edges[9].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={mtypefaceCloudinary.edges[10].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={mtypefaceCloudinary.edges[10].node.secure_url}
                alt=""
              />
            </div>
            <div className="col-12">
              <img
                className="img-fluid"
                src={mtypefaceCloudinary.edges[11].node.secure_url}
                alt=""
              />
            </div>
          </div>

          <div className="row p-t-400">
            <div className="col-12">
              <h4 className="mola-250 text-center ">In lak’ ech</h4>
              <div className="row">
                <div className="col-md-6">
                  <p className="text-center">Yo soy tu otro tu</p>
                </div>
                <div className="col-md-6">
                  <p className="text-center">I am another you</p>
                </div>
              </div>
            </div>
          </div>

          <div className="row p-t-200">
            <div className="col-12">
              <h4 className="mola-250 text-center ">Hala ken</h4>
              <div className="row">
                <div className="col-md-6">
                  <p className="text-center">Tú eres mi otro yo</p>
                </div>
                <div className="col-md-6">
                  <p className="text-center">as you are another me</p>
                </div>
              </div>
            </div>
          </div>

          <div className="row text-section p-t-200">
            <div className="col-lg-5">
              <p>Special Thank You</p>
            </div>
            <div className="col-lg-7">
              <p>Greg Lindy (Lux Typo) and Rolando Castillo (Designer)</p>
            </div>
          </div>
        </div>
      </div>
      <div ref={ref}></div>
      <Projects />
    </Layout>
  )
}
export default Typography
